import css from 'styled-jsx/css';

export default css`
.content_wrapper {
  width: 100%;
  height: calc(100vh - 1px);
  min-height: 350px;
}

.content_layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-height: 100%;
  margin: 0;
  box-sizing: border-box;
  overflow: hidden;
}


@media (min-width:320px) { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ }
@media (min-width:480px) {}
@media (min-width:600px) {}
@media (min-width:801px) {}
@media (min-width:1025px) {}
@media (min-width:1281px) {}
`;
